/** @jsx jsx */
import { jsx } from "@emotion/core"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import { Container } from "react-bootstrap"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 : Page non trouvée" />
    <Section css={{ marginTop: "60px" }}>
      <Container>
        <h1>Page non trouvée</h1>
        <p className="lead mb-0">
          Vous venez d'utiliser un chemin qui n'existe pas...
        </p>
      </Container>
    </Section>
  </Layout>
)

export default NotFoundPage
